import React, { useEffect } from "react";
import Layout from "../components/layout/layout";
import { ImageBackground, MainDiv } from "../components/styledComponents";
import Image from "../components/image";
import { css } from "@emotion/react";
import { StaticImage } from "gatsby-plugin-image";

const Menu = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);

  return (
    <Layout>
      <ImageBackground
        tag={"main"}
        fluid={[
          `linear-gradient(90deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.8) 100%)`,
          Image().menu,
        ]}
        css={css`
          margin-top: calc(-47px * 3);
          padding-top: calc(47px * 3);
          height: calc(100vh - 47px * 3);
          transform: skew(0deg, -5deg);

          .content {
            height: 100%;
            transform: skew(0deg, 5deg);
            font-family: "Brandon Grotesque", sans-serif;
            color: white;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            h1 {
              font-size: 70px;
              text-align: center;
              max-width: 600px;
              line-height: 1;
            }

            .logoContainer {
              position: absolute;
              right: 20px;
              bottom: 100px;

              .zamha-logo {
                width: 120px;
                height: 120px;
              }

              @media screen and (max-width: 1024px) {
                bottom: 70px;
              }

              @media screen and (max-width: 600px) {
                bottom: 30px;

                .zamha-logo {
                  width: 80px;
                  height: 80px;
                }
              }
            }

            .box {
              background-color: rgba(0, 0, 0, 0.8);
              padding: 1rem 2rem;
              border: 2px solid white;

              p {
                margin: 0;
              }
            }
          }
        `}
      >
        <div className="content">
          <h1>Menu</h1>
          <div className="logoContainer">
            <StaticImage
              src="../images/zamha.png"
              alt="ZAMHA Logo"
              className="zamha-logo"
              placeholder="tracedSVG"
            />
          </div>
        </div>
        <ImageBackground
          tag={"div"}
          fluid={Image().pattern2}
          css={css`
            height: 25px;
          `}
        />
      </ImageBackground>
      <MainDiv
        css={css`
          margin-top: 100px;
          //display: flex;
          //gap: 40px;
          //flex-wrap: wrap;
          //align-items: center;
          //justify-content: center;
          font-family: "Brandon Grotesque", sans-serif;
          overflow-y: hidden;

          display: flex;
          flex-direction: column;
          gap: 40px;

          h2 {
            margin: 0;
            font-size: 35px;

            :after {
              content: "";
              display: block;
              height: 2px;
              border-radius: 4px;
              width: 7%;
              background-color: #707070;
            }
          }

          .menuContainer {
            width: 100%;
            height: 100%;

            .menuImage {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        `}
      >
        <div className={"menuContainer"}>
          <StaticImage
            src={"../images/Menu_01_Front_19 July 22.jpg"}
            alt={"Menu Front"}
            className={"menuImage"}
          />
        </div>
        <div className={"menuContainer"}>
          <StaticImage
            src={"../images/Menu_01_Back_19 July 22.jpg"}
            alt={"Menu Front"}
            className={"menuImage"}
          />
        </div>
      </MainDiv>
    </Layout>
  );
};

export default Menu;
